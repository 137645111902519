export const areaCodeOptions = [
  { country: '中国大陆', area_code: '+86' },
  { country: '安道尔共和国', area_code: '+376' },
  { country: '阿拉伯联合酋长国', area_code: '+971' },
  { country: '阿富汗', area_code: '+93' },
  { country: '安提瓜和巴布达', area_code: '+1268' },
  { country: '安圭拉岛', area_code: '+1264' },
  { country: '阿尔巴尼亚', area_code: '+355' },
  { country: '亚美尼亚', area_code: '+374' },
  { country: '阿森松', area_code: '+247' },
  { country: '安哥拉', area_code: '+244' },
  { country: '阿根廷', area_code: '+54' },
  { country: '奥地利', area_code: '+43' },
  { country: '澳大利亚', area_code: '+61' },
  { country: '阿塞拜疆', area_code: '+994' },
  { country: '巴巴多斯', area_code: '+1246' },
  { country: '孟加拉国', area_code: '+880' },
  { country: '比利时', area_code: '+32' },
  { country: '布基纳法索', area_code: '+226' },
  { country: '保加利亚', area_code: '+359' },
  { country: '巴林', area_code: '+973' },
  { country: '布隆迪', area_code: '+257' },
  { country: '贝宁', area_code: '+229' },
  { country: '巴勒斯坦', area_code: '+970' },
  { country: '百慕大群岛', area_code: '+1441' },
  { country: '文莱', area_code: '+673' },
  { country: '玻利维亚', area_code: '+591' },
  { country: '巴西', area_code: '+55' },
  { country: '巴哈马', area_code: '+1242' },
  { country: '博茨瓦纳', area_code: '+267' },
  { country: '白俄罗斯', area_code: '+375' },
  { country: '伯利兹', area_code: '+501' },
  { country: '加拿大', area_code: '+1' },
  { country: '开曼群岛', area_code: '+1345' },
  { country: '中非共和国', area_code: '+236' },
  { country: '刚果', area_code: '+242' },
  { country: '瑞士', area_code: '+41' },
  { country: '库克群岛', area_code: '+682' },
  { country: '智利', area_code: '+56' },
  { country: '喀麦隆', area_code: '+237' },
  { country: '哥伦比亚', area_code: '+57' },
  { country: '哥斯达黎加', area_code: '+506' },
  { country: '古巴', area_code: '+53' },
  { country: '塞浦路斯', area_code: '+357' },
  { country: '捷克', area_code: '+420' },
  { country: '德国', area_code: '+49' },
  { country: '吉布提', area_code: '+253' },
  { country: '丹麦', area_code: '+45' },
  { country: '多米尼加共和国', area_code: '+1890' },
  { country: '阿尔及利亚', area_code: '+213' },
  { country: '厄瓜多尔', area_code: '+593' },
  { country: '爱沙尼亚', area_code: '+372' },
  { country: '埃及', area_code: '+20' },
  { country: '西班牙', area_code: '+34' },
  { country: '埃塞俄比亚', area_code: '+251' },
  { country: '芬兰', area_code: '+358' },
  { country: '斐济', area_code: '+679' },
  { country: '法国', area_code: '+33' },
  { country: '加蓬', area_code: '+241' },
  { country: '英国', area_code: '+44' },
  { country: '格林纳达', area_code: '+1809' },
  { country: '格鲁吉亚', area_code: '+995' },
  { country: '法属圭亚那', area_code: '+594' },
  { country: '加纳', area_code: '+233' },
  { country: '直布罗陀', area_code: '+350' },
  { country: '冈比亚', area_code: '+220' },
  { country: '几内亚', area_code: '+224' },
  { country: '希腊', area_code: '+30' },
  { country: '危地马拉', area_code: '+502' },
  { country: '关岛', area_code: '+1671' },
  { country: '圭亚那', area_code: '+592' },
  { country: '香港特别行政区', area_code: '+852' },
  { country: '洪都拉斯', area_code: '+504' },
  { country: '海地', area_code: '+509' },
  { country: '匈牙利', area_code: '+36' },
  { country: '印度尼西亚', area_code: '+62' },
  { country: '爱尔兰', area_code: '+353' },
  { country: '以色列', area_code: '+972' },
  { country: '印度', area_code: '+91' },
  { country: '伊拉克', area_code: '+964' },
  { country: '伊朗', area_code: '+98' },
  { country: '冰岛', area_code: '+354' },
  { country: '意大利', area_code: '+39' },
  { country: '科特迪瓦', area_code: '+225' },
  { country: '牙买加', area_code: '+1876' },
  { country: '约旦', area_code: '+962' },
  { country: '日本', area_code: '+81' },
  { country: '肯尼亚', area_code: '+254' },
  { country: '吉尔吉斯坦', area_code: '+331' },
  { country: '柬埔寨', area_code: '+855' },
  { country: '朝鲜', area_code: '+850' },
  { country: '韩国', area_code: '+82' },
  { country: '科威特', area_code: '+965' },
  { country: '哈萨克斯坦', area_code: '+327' },
  { country: '老挝', area_code: '+856' },
  { country: '黎巴嫩', area_code: '+961' },
  { country: '圣卢西亚', area_code: '+1758' },
  { country: '列支敦士登', area_code: '+423' },
  { country: '斯里兰卡', area_code: '+94' },
  { country: '利比里亚', area_code: '+231' },
  { country: '莱索托', area_code: '+266' },
  { country: '立陶宛', area_code: '+370' },
  { country: '卢森堡', area_code: '+352' },
  { country: '拉脱维亚', area_code: '+371' },
  { country: '利比亚', area_code: '+218' },
  { country: '摩洛哥', area_code: '+212' },
  { country: '摩纳哥', area_code: '+377' },
  { country: '摩尔多瓦', area_code: '+373' },
  { country: '马达加斯加', area_code: '+261' },
  { country: '马里', area_code: '+223' },
  { country: '缅甸', area_code: '+95' },
  { country: '蒙古', area_code: '+976' },
  { country: '澳门', area_code: '+853' },
  { country: '蒙特塞拉特岛', area_code: '+1664' },
  { country: '马耳他', area_code: '+356' },
  { country: '马里亚那群岛', area_code: '+1670' },
  { country: '马提尼克', area_code: '+596' },
  { country: '毛里求斯', area_code: '+230' },
  { country: '马尔代夫', area_code: '+960' },
  { country: '马拉维', area_code: '+265' },
  { country: '墨西哥', area_code: '+52' },
  { country: '马来西亚', area_code: '+60' },
  { country: '莫桑比克', area_code: '+258' },
  { country: '纳米比亚', area_code: '+264' },
  { country: '尼日尔', area_code: '+227' },
  { country: '尼日利亚', area_code: '+234' },
  { country: '尼加拉瓜', area_code: '+505' },
  { country: '荷兰', area_code: '+31' },
  { country: '挪威', area_code: '+47' },
  { country: '尼泊尔', area_code: '+977' },
  { country: '荷属安的列斯', area_code: '+599' },
  { country: '瑙鲁', area_code: '+674' },
  { country: '新西兰', area_code: '+64' },
  { country: '阿曼', area_code: '+968' },
  { country: '巴拿马', area_code: '+507' },
  { country: '秘鲁', area_code: '+51' },
  { country: '法属玻利尼西亚', area_code: '+689' },
  { country: '巴布亚新几内亚', area_code: '+675' },
  { country: '菲律宾', area_code: '+63' },
  { country: '巴基斯坦', area_code: '+92' },
  { country: '波兰', area_code: '+48' },
  { country: '波多黎各', area_code: '+1787' },
  { country: '葡萄牙', area_code: '+351' },
  { country: '中国台湾', area_code: '+886' },
  { country: '俄罗斯', area_code: '+7' },
  { country: '新加坡', area_code: '+65' },
  { country: '泰国', area_code: '+66' },
  { country: '越南', area_code: '+84' },
  { country: '东帝汶', area_code: '+670' },
]
